import fetch from 'isomorphic-fetch';

const checkStatus = (res) => {
  if (res.status >= 200 && res.status < 300) return res;
  const error = new Error(res.statusText);
  error.response = res;
  error.statusCode = res.status;
  throw error;
};

const parseJSON = response => response.json();

const baseFetch = (path, options) => fetch(path, options)
  .then(checkStatus)
  .then(parseJSON);

export const createParamString = params => Object.keys(params)
  .filter(key => (params[key] !== undefined && params[key] !== null))
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

export default baseFetch;
