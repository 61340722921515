import { EventTypes } from 'redux-segment';
import canUseDOM from '../common/canUseDOM';

// All segment events should fire the same action.
export const SEGMENT_EVENT = 'SEGMENT_EVENT';

/**
 * Segment action used to identify a page event
 *
 * This action should typically get called from the constructor. Any meta
 * data required to fire the action can be passed through from the connected
 * route container.
 *
 * @param {Object} payload an object to send the event payload request.
 */
export const navigatedToPage = (name, properties = {}) => (dispatch) => {
  if (canUseDOM) {
    dispatch({
      type: SEGMENT_EVENT,
      meta: {
        analytics: {
          eventType: EventTypes.page,
          eventPayload: { name, properties },
        },
      },
    });
  }
};

export const trackEvent = (name, properties) => async (dispatch) => {
  await dispatch({
    type: SEGMENT_EVENT,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: name,
          properties: { ...properties },
        },
      },
    },
  });
};
