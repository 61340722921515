import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import config from '../../config';

const HelmetWrapper = ({ title, description, urlPath, image }) => {
  const seoUrl = `${config.hostForSEO}${urlPath}`;
  const url = `${config.morroNegritoHost}${urlPath}`;
  const link = [
    {
      rel: 'canonical',
      href: seoUrl,
    },
  ];

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:image',
      content: image,
    },
    {
      property: 'og:description',
      content: description,
    },
  ];

  const robotsMeta = config.robots ?
  {
    name: 'robots',
    content: config.robots,
  } : null;

  if (robotsMeta) {
    meta.push(robotsMeta);
  }

  return (
    <Helmet
      title={title}
      meta={meta}
      link={link}
    />
  );
};

HelmetWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  image: PropTypes.string,
};

HelmetWrapper.defaultProps = {
  image: config.facebookOGImage,
};

export default HelmetWrapper;
